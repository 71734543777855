import { Link } from "gatsby"
import React, { useState } from "react"
import MainMenu from "./layout/MainMenu"

export default function Header({ menu, location }) {
  const [open, setOpen] = useState(false)

  return (
    <>
      <div className="absolute z-50 w-full  top-0 ">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 py-2 lg:py-5">
          <div className="flex justify-between items-center py-2 lg:justify-start  ">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              {/* <Link to={"/"}>
                <span className="sr-only">pilates-radotin.cz</span>
                <img src={Logo} className="h-24 lg:h-48" alt="Logo" />
              </Link> */}
            </div>
            <div></div>
            <div className="lg:order-1 flex">
              <div className="lg:order-2 flex items-center justify-end lg:flex-1 lg:ml-10">
                {/* <MiniCart  /> */}
              </div>
              <div className="lg:order-1 z-50 ">
                <MainMenu
                  open={open}
                  setOpen={setOpen}
                  menu={menu}
                  location={location}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
