import React, { useCallback, useEffect, useReducer, useState } from "react"
import { Reducer, SET_ITEMS } from "./reducers"
import { PageContext } from "./context"
import axiosInstance from "../client"

export const AuthContextProvider = props => {
  const [token, setToken] = useState(JSON.parse(localStorage.getItem("token")))
  const [cartId, setCartId] = useState(localStorage.getItem("cartId"))

  function updateCartId() {
    let cartHash
    cartHash =
      "cart_" +
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15)
    localStorage.setItem("cartId", cartHash)
    setCartId(cartHash)
  }

  useEffect(() => {
    // if (!cartId) {
    //   updateCartId()
    // }
    // async function getItems() {
    //   try {
    //     await axiosInstance.post("cart/items/" + cartId + "/").then(res => {
    //       dispatch({ type: SET_ITEMS, data: res.data })
    //     })
    //   } catch (err) {
    //     console.log(err)
    //   }
    // }
    // getItems()
    // eslint-disable-next-line
  }, [])

  const addProductToCart = async product => {
    axiosInstance
      .post("cart/item_add/" + state?.cart?.cart?.cartHash + "/", {
        itemId: product.id,
        type: "goods",
        price: product.priceVat,
      })
      .then(res => {
        dispatch({ type: SET_ITEMS, data: res.data })
      })
      .catch(err => {
        console.log(err)
        // This probably means your response is text, do you text handling here
      })
  }

  function tokenExpiration(logout) {
    const token = JSON.parse(localStorage.getItem("token"))
    const expiration = token?.expiration
    if (token) {
      const remainingTime =
        new Date(expiration).getTime() - new Date().getTime()
      setTimeout(function () {
        logout()
      }, remainingTime)
    } else {
      logout()
    }
  }

  const login = useCallback(data => {
    const token = JSON.parse(localStorage.getItem("token"))
    setToken(token)
    let userData
    if (data) {
      userData = {
        ...data.userData,
        id: data.userData._id,
        userId: parseInt(data._id),
        groups: data.groups,
      }
      localStorage.setItem("userData", JSON.stringify(userData))
    } else {
      userData = JSON.parse(localStorage.getItem("userData"))
    }
    dispatch({
      type: "SET_USER",
      payload: {
        data: userData,
        token: token,
      },
    })
  }, [])

  const logout = useCallback(() => {
    setToken(null)
    localStorage.removeItem("userData")
    localStorage.removeItem("token")
    dispatch({ type: "CLEAR_USER" })
  }, [])

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("token"))
    const storedData = JSON.parse(localStorage.getItem("userData"))
    if (storedData && token) {
      login()
    }
  }, [login])

  useEffect(() => {
    tokenExpiration(logout)
  }, [token, logout])

  useEffect(() => {
    if (token) {
      const remainingTime =
        new Date(token.expiration).getTime() - new Date().getTime()
      if (remainingTime < 10000) {
        logout()
      }
    }
    if (token) {
      async function token() {
        await tokenExpiration(logout)
      }
      token()
    }
    // eslint-disable-next-line
  }, [login])

  const initialState = {
    isLoggedIn: !!token,
    token: token,
    login: login,
    logout: logout,
    addProductToCart: addProductToCart,
    userData: null,
    modal: { open: false },
    products: [],
    cart: { open: false },
    lastProduct: {},
    menu: [],
    cats: [],
    breadCrumbPath: [],
    miniCart: { open: false },
    storage: true,
    searchString: null,
  }

  const [state, dispatch] = useReducer(Reducer, initialState)

  return (
    <PageContext.Provider value={[state, dispatch]}>
      {props.children}
    </PageContext.Provider>
  )
}
