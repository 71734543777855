import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  HttpLink,
} from "@apollo/client"
import { onError } from "@apollo/client/link/error"

import { CONFIG_APP_API_ROUTE } from "./index"

const STAGE = process.env.NODE_ENV
let fetchOptions
if (STAGE === "development") {
  fetchOptions = {
    credentials: "include",
    //mode: "no-cors",
  }
} else {
  fetchOptions = {
    credentials: "include",
    //mode: "cors",
  }
}

const httpLink = new HttpLink({ uri: CONFIG_APP_API_ROUTE + "api/graphql" })

const authMiddleware = () =>
  new ApolloLink(async (operation, forward) => {
    const token = JSON.parse(localStorage.getItem("token"))
    if (token) {
      operation.setContext({
        headers: {
          //"Access-Control-Allow-Origin": "*",
          authorization: `Bearer ${token.token}`,
        },
        fetchOptions: fetchOptions,
      })
    }

    return forward(operation)
  })

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  const { response } = operation.getContext()
  if (response && response.status === 401) {
    response.errors = null
  }
})

export const apolloClient = new ApolloClient({
  link: authMiddleware().concat(errorLink).concat(httpLink),
  cache: new InMemoryCache(),
  fetchOptions: fetchOptions,
})
